<template>
  <div class="home col">
    <b-card
      overlay
      img-src="../assets/home/screens-aragon-1.5-1280x594.jpg"
      img-alt="Header img"
      text-variant="white"
      title="Geekline - creativity &amp; innovation first!"
      sub-title="Passion to innovate!"
      style="text-align: center;">
    </b-card>

    <div style="text-align: center; font-size: 28px;">
      <strong>Learn more about our rich products</strong>
    </div>
    <div class="row" style="justify-content: space-around;">
      <div
        v-for="(inst) in instTypeCat"
        :key="inst.code"
        class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
        <b-card
          header-class="text-center"
          :title="inst.title"
          style="background-color: lightblue; margin: 2px;">
          <template v-slot:header>
            <b-icon icon="image" font-scale="1"></b-icon>
          </template>
          <transition name="bounce">
            <p v-if="!instOrdered[inst.code]">
              {{ inst.description }}
            </p>
          </transition>
          <transition name="bounce">
            <p v-if="instOrdered[inst.code]">Congratulations! You have ordered your {{ inst.title }}! You can click <b-link :to="{ name: 'dashboard' }">here</b-link> to vuew your instances.</p>
          </transition>
          <template v-slot:footer>
            <center>
              <b-button v-if="!instOrdered[inst.code]" @click="createInstance(inst.code)">Add to subscription</b-button>
            </center>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import productsMeta from './meta/products'
import { actions as accountActionsNames,
  getNamespacedName } from '@/store/modules/account/names'
import { states as stateNames, actions as glapiActionsNames,
  moduleNamespace as glapiModuleNSp } from '@/store/modules/glapi/names'

const { mapState } = createNamespacedHelpers(glapiModuleNSp)

export default {
  name: 'products',
  meta: {
    localizedHtmlMeta: productsMeta
  },
  data () {
    return {
      instOrdered: []
    }
  }, // data
  /* ----- vuejs hooks ----- */
  async mounted () {
    try {
      await this.$store.dispatch(glapiModuleNSp + '/' + glapiActionsNames.FETCH_INST_TYPE_CAT)
      console.log('TRACE-REMOVE: Products.mounted: this.instTypeCat is: ', this.instTypeCat)
      this.instTypeCat.forEach(e => { this.instOrdered[e.code] = false })
    } catch (e) {
      this.$bvToast.toast(e.name + ': ' + e.message)
      console.log('TRACE-REMOVE: exception is: ', e)
    } finally {

    }
  }, // mounted
  async created () {
    this.$tracker('event', 'screen_view', {
      'app_name': 'geekline-web',
      'screen_name': 'Products'
    })
  }, // created
  computed: {
    ...mapState([[stateNames.INST_TYPE_CAT]])
  },
  methods: {
    /* ---------- mapped methods ---------- */
    // ...mapActions(glapiModuleNSp, [])
    async createInstance (itCode) {
      try {
        // TODO: consider using $store for auth check!
        if (!this.$auth.isAuthenticated) {
          this.$bvToast.toast('You have to be logged in to add product to your subscription!')
        } else {
          await this.$store.dispatch(getNamespacedName(accountActionsNames.CREATE_INSTANCE), itCode)
          this.instOrdered[itCode] = !this.instOrdered[itCode]
          setTimeout(() => {
            this.instOrdered[itCode] = !this.instOrdered[itCode]
          }, 10000)
          this.$bvToast.toast('Instance created successfully!')
        }
      } catch (e) {
        // console.log('TRACE-REMOVE: exception is: ', e)
        this.$bvToast.toast(e.name + ': ' + e.message)
      } finally {

      }
    }
  }
}
</script>

<style scoped>
.cmpn {
  margin-top: 85px;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
