<template>
  <div class="cmpn col">
    <b-card
      overlay
      img-src="../assets/home/screens-aragon-1.5-1280x594.jpg"
      img-alt="Header img"
      text-variant="white"
      title="Geekline - creativity & innovation first!"
      sub-title="Passion to innovate!"
      style="text-align: center;">
      <!-- <b-card-text>Get started</b-card-text> -->
    </b-card>
    <b-card
      header-class="text-center"
      title="You have questions?"
      style="background-color: lightblue; margin: 2px;">
      <template v-slot:header>
        <i class="mdi mdi-contact-mail" style="font-size: 32px;"></i>
      </template>
      <p>
        Drop us an email at <strong>ivanov.consulting@gmail.com</strong> or contact us on social media (buttons below) <br/>
        <strong>Postal address:</strong> <br/>
        Ognyan Ivanov<br/>
        Speicherstr. 35<br/>
        60327 Frankfurt am Main<br/><br/>
        If you want to learn more about the founder, click
        <b-link :href="$router.localizedRoute('/author/oggo')">here</b-link> or one of the buttons below!
      </p>
      <template v-slot:footer>
        <center>
          <!-- <b-button class="mx-2"><i class="mdi mdi-facebook-box" style="font-size: 16px;"></i></b-button> -->
          <b-icon icon="facebook" font-scale="1"></b-icon>
          <!-- <b-button class="mx-2"><i class="mdi mdi-linkedin-box" style="font-size: 16px;"></i></b-button> -->
          <b-icon icon="instagram" font-scale="1"></b-icon>
          <!-- <b-button class="mx-2"><i class="mdi mdi-twitter-box" style="font-size: 16px;"></i></b-button> -->
          <b-icon icon="linkedin" font-scale="1"></b-icon>
        </center>
      </template>
    </b-card>
  </div>
</template>
<script>
import contactMeta from './meta/contact'

// const pageDescription = 'Responsive web design in html5, javascript. Python,java based REST or GraphQL Backend. Cloud and Big Data solutions. Online Marketing and SEO. SSL, STL, OAuth2.'
export default {
  name: 'contact',
  meta: {
    localizedHtmlMeta: contactMeta
  }, // meta
  created () {
    this.$tracker('event', 'screen_view', {
      'app_name': 'geekline-web',
      'screen_name': 'Contact'
    })
  },
  components: {
  }
}
</script>
<style>
.cmpn {
  margin-top: 85px;
}
</style>
