<template>
  <div class="cmpn col">
    <b-card
      header-class="text-center"
      title="Informations about Ognyan Ivanov"
      style="background-color: lightblue; margin: 2px;">
      <template v-slot:header>
        <i class="mdi mdi-account-details" style="font-size: 32px;"></i>
      </template>
      <b-img class="col-3" src="../../assets/author/oggo/Ivanov-1-3000x2950-2.jpg" thumbnail></b-img>
      <b-tabs content-class="mt-3" pills>
        <b-tab title="About" active>
          <p>Passionate software engineer, focused on design, development, test and delivery of high quality software products and services. Since the early days i started to deal with software development more than 20 years ago my claim has always been perfection in every detail! Here some of the major organizations i have gathered experience through the years:</p>
          <p>
            <strong>01.2013 - present:</strong><br/>
            <strong>Position:</strong> Team lead, Senior architect and developer<br/>
            <strong>Customer:</strong> Biggest Railway company in Germany<br/>
            <strong>Contract:</strong> Freelancer<br/>
            <strong>Description:</strong> Definition of processes of requirement management, software development, test and documentation, continuous integration and delivery. Team lead activities as assignment of implementation issues, supervising of implementation and development test, managing the integration and delivery of the software and implementing infrastructure software for amplifying the development of the features. Production analysis and performance tuning.<br/>
          </p>
          <br/>
          <p>
            <strong>05.2012 - 12.2012:</strong><br/>
            <strong>Position:</strong> Technical Project lead, Senior architect<br/>
            <strong>Customer:</strong> Biggest Telecom in Spain and one of the biggest in Latin America<br/>
            <strong>Contract:</strong> Freelancer<br/>
            <strong>Description:</strong> Project planning, definition of delivery and acceptance phases and rules. Definition of processes of requirement management, software development, test and documentation, continuous integration and delivery, hardware sizing. Supervising of implementation and development test, managing the integration and delivery of the software and design of the products architecture. Production analysis and performance tuning.<br/>
          </p>
          <br/>
          <p>
            <strong>01.2008 - 04.2012:</strong><br/>
            <strong>Position:</strong> Senior developer<br/>
            <strong>Customer:</strong> Biggest Telecom in Germany<br/>
            <strong>Contract:</strong> Freelancer<br/>
            <strong>Description:</strong> Software development, test and documentation, continuous integration and delivery, hardware sizing. Implementation and development test, managing the integration and delivery of the software and design of the products architecture. Production analysis and performance tuning.<br/>
          </p>
          <br/>
          <p>
            <strong>11.2000 - 12.2008:</strong><br/>
            <strong>Position:</strong> Developer / senior developer<br/>
            <strong>Customer:</strong> Biggest Bank in Germany<br/>
            <strong>Contract:</strong> Employee by consultancy company<br/>
            <strong>Description:</strong> Software development, test and documentation, continuous integration and delivery, hardware sizing. Junior team lead. Implementation and development test, managing the integration and delivery of the software and design of the products architecture. Production analysis and performance tuning.<br/>
          </p>
          <br/>
          <p>
            <strong>10.1998 - 10.2000:</strong><br/>
            <strong>Position:</strong> Junior developer / developer<br/>
            <strong>Customer:</strong> Diverse<br/>
            <strong>Contract:</strong> Employee by consultancy company<br/>
            <strong>Description:</strong> Software development, test and documentation, continuous integration and delivery, hardware sizing. Implementation and development test, managing the integration and delivery of the software and design of the products architecture. Production analysis and performance tuning.<br/>
          </p>
        </b-tab>
        <b-tab title="Skills and tools" disabled><p>// TBD</p></b-tab>
        <b-tab title="Education / Service">
          <strong>01.1998 - 09.1998:</strong> Military service<br/>
          <strong>10.1992 - 07.1997:</strong> Technical University - Sofia, Master of computer science<br/>
          <strong>09.1988 - 05.1992:</strong> High school of mathematics - Vidin, Bulgaria<br/>
        </b-tab>
        <b-tab title="Contact">
          <p><strong>eMail:</strong> ivanov.consulting@gmail.com</p>
          <p><strong>Web:</strong> geekline.org</p>
        </b-tab>
      </b-tabs>
      <template v-slot:footer>
        <center>
          <b-button class="mx-2"><i class="mdi mdi-facebook-box" style="font-size: 16px;"></i></b-button>
          <b-button class="mx-2"><i class="mdi mdi-linkedin-box" style="font-size: 16px;"></i></b-button>
          <b-button class="mx-2"><i class="mdi mdi-twitter-box" style="font-size: 16px;"></i></b-button>
        </center>
      </template>
    </b-card>
  </div>
</template>
<script>
import aboutOggoMeta from './meta/aboutOggo'

export default {
  name: 'aboutOggo',
  // meta: aboutOggoMeta(),
  meta: {
    localizedHtmlMeta: aboutOggoMeta
  }, // meta
  created () {
    this.$tracker('event', 'screen_view', {
      'app_name': 'geekline-web',
      'screen_name': 'Author / About Ognyan Ivanov'
    })
  }
}
</script>
<style>
.cmpn {
  margin-top: 100px;
}
</style>
