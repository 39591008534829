const pageTitle = 'Ognyan Ivanov - CEO and founder by Geekline'
const pageDescription = 'Ognyan Ivanov is a software engineer and passionate technology geek. Here is how it came to that.'

export default (locale) => {
  return {
    title: pageTitle,
    metaTags: [
      {
        name: 'description', /** 155-160 symbols */
        content: pageDescription
      }, // 150-165 symbols
      {
        property: 'og:title',
        content: pageTitle
      },
      {
        property: 'og:description',
        content: pageDescription
      },
      {
        property: 'og:url',
        content: 'https://geekline.org/' + locale + '/author/oggo'
      }
    ],
    jsonld: {
      '@context': 'http://schema.org',
      '@graph': [
        {
          '@type': 'WebPage',
          '@id': 'https://geekline.org/' + locale + '/author/oggo/#webpage',
          'url': 'https://geekline.org/' + locale + '/author/oggo',
          'inLanguage': locale,
          'name': 'geekline - author oggo page',
          'isPartOf': {
            '@id': 'https://geekline.org/#website'
          },
          'datePublished': '2020-01-31T01:40:11+01:00',
          'dateModified': '2020-02-20T19:40:11+01:00',
          'description': pageDescription
        },
        {
          '@type': 'Person',
          '@id': 'https://geekline.org/' + locale + '/author/oggo/#schema-author',
          'url': 'https://geekline.org/' + locale + '/author/oggo/',
          'name': 'Ognyan Ivanov',
          'image': {
            '@type': 'ImageObject',
            '@id': 'https://geekline.org/author/oggo/#author-image',
            'url': 'https://geekline.org/author/oggo/Ivanov-1-3000x2950-2.jpg',
            'caption': 'Ognyan Ivanov'
          },
          'description': pageDescription,
          'sameAs': [
            'https://web.facebook.com/oggzter',
            'https://www.linkedin.com/in/ognyan-ivanov-40bb625/'
          ]
        }
      ]
    }
  }
}
