<template>
  <div class="home col">
    <b-toast id="subscr-not-available-toast" variant="warning" solid>
      <template v-slot:toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12"></b-img>
          <strong class="mr-auto">Warning!</strong>
        </div>
      </template>
      This service is still not available! We are working hard to set it up! <strong>Currently is only play subscription available!</strong>
    </b-toast>
    <b-card
      overlay
      img-src="../assets/home/screens-aragon-1.5-1280x594.jpg"
      img-alt="Header img"
      text-variant="white"
      title="Geekline - creativity &amp; innovation first!"
      sub-title="Passion to innovate!"
      style="text-align: center;">
    </b-card>

    <div style="text-align: center; font-size: 28px;">
      <p><strong>Here you can choose from our amaizing subscription plans</strong></p>
      <p>recurrent subscriptions:</p>
    </div>
    <div class="row" style="justify-content: space-around;">
      <div class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
        <b-card
          header-class="text-center"
          :title="subscrTypeCat[0].title"
          style="background-color: lightblue; margin: 2px;">
          <template v-slot:header>
            <b-icon icon="image" font-scale="1"></b-icon>
          </template>
          <p>
            {{ subscrTypeCat[0].description }}
          </p>
          <center>
            <b-link>read more here</b-link>
          </center>
          <template v-slot:footer>
            <center>
              <b-button @click="subscribe(subscrTypeCat[0].code)">subscribe</b-button>
            </center>
          </template>
        </b-card>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
        <b-card
          header-class="text-center"
          title="Start - 10 € / month"
          style="background-color: lightblue; margin: 2px;">
          <template v-slot:header>
            <b-icon icon="code-square" font-scale="1"></b-icon>
          </template>
          <p>
            The perfect choice for a small starting companies with flexible conditions and affordable service prices.
          </p>
          <center>
            <b-link>read more here</b-link>
          </center>
          <template v-slot:footer>
            <center>
              <b-button @click="subscribe('start')">subscribe</b-button>
            </center>
          </template>
        </b-card>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
        <b-card
          header-class="text-center"
          title="Business - 200 € / month"
          style="background-color: lightblue; margin: 2px;">
          <template v-slot:header>
            <b-icon icon="graph-up" font-scale="1"></b-icon>
          </template>
          <p>
            High scalability solution, perfectly fitting for fast expanding businesses and growth oriented ones.
          </p>
          <center>
            <b-link>read more here</b-link>
          </center>
          <template v-slot:footer>
            <center>
              <b-button @click="subscribe('businesses')">subscribe</b-button>
            </center>
          </template>
        </b-card>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
        <b-card
          header-class="text-center"
          title="Enterprise - 500 € / month*"
          style="background-color: lightblue; margin: 2px;">
          <template v-slot:header>
            <b-icon icon="map" font-scale="1"></b-icon>
          </template>
          <p>
            Large scale high availability solutions with option of dynamic expansion and balance of the resources.
          </p>
          <center>
            <b-link>read more here</b-link>
          </center>
          <template v-slot:footer>
            <center>
              <b-button @click="subscribe('enterprise')">subscribe</b-button>
            </center>
          </template>
        </b-card>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
        <b-card
          header-class="text-center"
          title="Corporate - negotiation* "
          style="background-color: lightblue; margin: 2px;">
          <template v-slot:header>
            <b-icon icon="globe" font-scale="1"></b-icon>
          </template>
          <p>
            Large globally scaled systems, optimally tailored for maximal performance and low latency by heavy load.
          </p>
          <center>
            <b-link>read more here</b-link>
          </center>
          <template v-slot:footer>
            <center>
              <b-button @click="subscribe('corporate')">subscribe</b-button>
            </center>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import servicesMeta from './meta/services'
import { actions as actionNames,
  getters as getterNames,
  getNamespacedName,
  moduleNamespace as accntModuleNamespace } from '@/store/modules/account/names'
import { states as stateNames,
  moduleNamespace as glapiModuleNamespace } from '@/store/modules/glapi/names'

const { mapGetters } = createNamespacedHelpers(accntModuleNamespace)
const { mapState } = createNamespacedHelpers(glapiModuleNamespace)

export default {
  name: 'services',
  meta: {
    localizedHtmlMeta: servicesMeta
  },
  data () {
    return {}
  }, // data
  async created () {
    this.$tracker('event', 'screen_view', {
      'app_name': 'geekline-web',
      'screen_name': 'Services'
    })
    // console.log('TRACE-REMOVE: Service.created: this.$store.state.account.token is: ', this.$store.state.account.token)
    // TODO: consider loading progress!
    try {
      await this.$store.dispatch(getNamespacedName(actionNames.FETCH_SUBSCRIPTIONS))
    } catch (e) {
      this.$bvToast.toast(e.name + ': ' + e.message)
    }
  }, // created
  computed: {
    ...mapState([[stateNames.SUBSCR_TYPE_CAT]]),
    ...mapGetters([[getterNames.HAS_SUBSCRIPTION]])
  },
  methods: {
    async subscribe (subscr) {
      if (subscr === 'st-play') {
        // TODO: consider using $store for auth check!
        if (!this.$auth.isAuthenticated) {
          this.$bvToast.toast('You have to be logged in to order subscription!')
        } else {
          try {
            await this.$store.dispatch(getNamespacedName(actionNames.SUBSCRIBE), subscr)
            this.$bvToast.toast('Subscription created successfully!')
          } catch (e) {
            // console.log('TRACE-REMOVE: cmpn.Services: cought error: ', e)
            this.$bvToast.toast(e.name + ': ' + e.message)
          }
        }
      } else {
        // this.$bvToast.toast('This service is still not available! We are working hard to set it up! <strong>Currently is only play subscription available!</strong>')
        this.$bvToast.show('subscr-not-available-toast')
      }
    }
  }
}
</script>

<style scoped>
.cmpn {
  margin-top: 85px;
}
</style>
